import type { GetProductResponse } from '@vue-storefront/prestashop-api';
import { useSdk } from '~/sdk';

interface ProductState {
  data: GetProductResponse['psdata'] | null;
  pending: boolean;
  error: Error | null;
}

export const useProduct = () => {
  const key = useKey();
  const { t } = useI18n();
  const state = useState<ProductState>(`${key.value}-product`, () => ({
    data: null,
    pending: false,
    error: null,
  }));

  async function fetchProduct() {
    state.value.pending = true;

    const { id, slug, groupId, attributeId } = useProductParams();

    const data = await useSdk().commerce.getProduct({ id, groupId, attributeId });

    if (!data || !data.active || data.active == '0') {
      throw createError({
        statusCode: 404,
        message: t('Product not found'),
      });
    }
    state.value.pending = false;
    state.value.data = data;
  }

  return {
    data: computed(() => state.value.data),
    pending: computed(() => state.value.pending),
    error: computed(() => state.value.error),
    refresh: fetchProduct,
    execute: fetchProduct,
  };
};

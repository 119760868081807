<script lang="ts" setup>
import type { SpecificPrices } from '@vue-storefront/prestashop-api';

interface PricesData {
  specific_prices: SpecificPrices;
  price: string;
  discount_percentage: string;
  regular_price: string;
  discount_amount?: number;
}

interface Props {
  pricesData: PricesData;
  size?: string;
  containerStyles?: string;
  discoundStyles?: string;
  regularPriceStyles?: string;
}

const props = withDefaults(defineProps<Props>(), { size: 'md' });

const isRegularPrice = computed(() => {
  return !props.pricesData?.specific_prices || parseInt(props.pricesData?.specific_prices?.price) === -1 || props.pricesData?.price == props.pricesData.regular_price;
})
</script>

<template>
  <div class="flex divide-x-2 items-center @container">
    <div class="flex flex-col pr-2">
      <span class="text-xs uppercase text-gray-400 mb-2">{{ $t('products.price', 'Price') }}</span>
      <span class="text-xl @md:text-2xl font-bold">{{ pricesData.price }}</span>
    </div>
    <template v-if="!isRegularPrice">
      <div class="flex flex-col px-2">
        <span class="text-xs uppercase text-gray-400 mb-2">{{ $t('products.save', 'Save') }}</span>
        <div class="text-base flex items-center gap-2">
          <span class="hidden @lg:block font-semibold">{{ pricesData.discount_amount }}</span>
          <span class="text-red-600 rounded-sm bg-red-100 px-2 py-px font-bold">{{ Math.round(parseFloat(pricesData?.specific_prices?.reduction) * 100) + '%' }}</span>
        </div>
      </div>
      <div class="flex flex-col pl-2">
        <span class="text-xs uppercase text-gray-400 mb-2">{{ $t('products.list_price', 'List Price') }}</span>
        <span class="text-base line-through">{{ pricesData.regular_price }}</span>
      </div>
    </template>
  </div>
</template>

<template>
<!-- <Swiper 
  id="gallery"
  :modules="modules"
  :slides-per-view="1"
  navigation
  :pagination="{ clickable: true }"
>
  <SwiperSlide v-for="(image, index) in images" :key="image.src">
    <Card class="border >
      <CardContent>
        <a
          :key="index"
          target="_blank"
          rel="noreferrer"
          :href="image.src"
          :data-pswp-width="500"
          :data-pswp-height="650"
        >
          <NuxtImg 
            :src="image.src"
            format="webp"
            loading="lazy" 
            :alt="`${product.name} - ${index + 1}`"
            :title="`${product.name} - ${index + 1}`" 
            class="aspect-square object-cover w-full" 
          />
        </a>
      </CardContent>
    </Card>
  </SwiperSlide>
</Swiper>
 -->



<div class="flex flex-col gap-4">
  <Carousel
    id="gallery"
    class="relative w-full"
    @init-api="(val) => emblaMainApi = val"
  >
    <CarouselContent>
      <CarouselItem v-for="(image, index) in images" :key="image.src">
        <Card class="aspect-square bg-gray-100">
          <a
            :key="index"
            target="_blank"
            rel="noreferrer"
            :data-pswp-src="image.src"
            :data-pswp-width="500"
            :data-pswp-height="650"
          >
            <NuxtImg 
              :src="image.src"
              format="webp"
              loading="lazy" 
              :alt="`${product.name} - ${index + 1}`"
              :title="`${product.name} - ${index + 1}`" 
              class="aspect-square object-cover w-full" 
            />
          </a>
        </Card>
      </CarouselItem>
    </CarouselContent>
    <CarouselPrevious class="ml-16 w-12 h-12">
      <ChevronLeft :size="32"/>
    </CarouselPrevious>
    <CarouselNext  class="mr-16 w-12 h-12 text-3xl">
      <ChevronRight :size="32"/>
    </CarouselNext>
  </Carousel>

  <Carousel
    class="relative w-full"
    @init-api="(val) => emblaThumbnailApi = val"
  >
    <CarouselContent class="flex flex-row gap-3 ml-0">
      <CarouselItem v-for="(image, index) in images" :key="image.src" class="pl-0 w-24 max-w-24 cursor-pointer" @click="onThumbClick(index)">
        <Card :class="index === selectedIndex ? 'border-primary' : ''">
          <CardContent class="flex aspect-square items-center justify-center p-6" 
          :class="index === selectedIndex ? '' : 'opacity-50'">
            <NuxtImg 
              :src="image.src"
              :alt="`${product.name} - ${index + 1}`"
              :title="`${product.name} - ${index + 1}`" 
              class="aspect-square object-cover w-full" 
            />
          </CardContent>
        </Card>
      </CarouselItem>
    </CarouselContent>
  </Carousel>
</div>
</template>

<script lang="ts" setup>
import { Navigation, Pagination, A11y } from 'swiper/modules';
import { ChevronLeft, ChevronRight } from 'lucide-vue-next';
import { Card, CardContent } from '@/components/ui/card'
import { Carousel, type CarouselApi, CarouselContent, CarouselItem, CarouselNext, CarouselPrevious } from '@/components/ui/carousel'
import { watchOnce } from '@vueuse/core'
import { ref } from 'vue'

const emblaMainApi = ref<CarouselApi>()
const emblaThumbnailApi = ref<CarouselApi>()
const selectedIndex = ref(0)

function onSelect() {
  if (!emblaMainApi.value || !emblaThumbnailApi.value)
    return
  selectedIndex.value = emblaMainApi.value.selectedScrollSnap()
  emblaThumbnailApi.value.scrollTo(emblaMainApi.value.selectedScrollSnap())
}

function onThumbClick(index: number) {
  if (!emblaMainApi.value || !emblaThumbnailApi.value)
    return
  emblaMainApi.value.scrollTo(index)
}

watchOnce(emblaMainApi, (emblaMainApi) => {
  if (!emblaMainApi)
    return

  onSelect()
  emblaMainApi.on('select', onSelect)
  emblaMainApi.on('reInit', onSelect)
})

// Type definition for product
interface Product {
  name: string;
}

onMounted(() => {
  usePhotoSwiper();
});

defineProps({
  images: Array as PropType<{ src: string }[]>,
  isOpen: {type: Boolean},
  product: {
    type: Object as PropType<Product>,
    default: {
      name : "Product Image"
    },
  },
});

const emit = defineEmits(['showGallery']);
const modules = [Navigation, Pagination, A11y];
</script>

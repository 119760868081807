import type { Product, SpecificPrices } from '@vue-storefront/prestashop-api';

export default function usePricesData(product: any) {
  interface PricesData {
    specific_prices: SpecificPrices;
    price: string;
    discount_percentage: string;
    discount_amount: string;
    regular_price: string;
  }

  const data = computed(
    (): PricesData => ({
      specific_prices: product.value.specific_prices,
      price: product.value.price,
      regular_price: product.value.regular_price,
      discount_amount: product.value.discount_amount,
      discount_percentage: product.value.discount_percentage,
    }),
  );

  const isRegular = computed(() => {
    return (
      !data.value.specific_prices ||
      parseInt(data.value.specific_prices.price) === -1 ||
      data.value.price == data.value.regular_price
    );
  });

  return { data, isRegular };
}

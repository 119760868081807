<script lang="ts" setup>
import { useProduct } from '@/composables/useProduct'; // Adjust import if necessary
import { SfLoaderCircular } from '@storefront-ui/vue';
import type { GetProductResponse } from '@vue-storefront/prestashop-api';
import { computed, onMounted, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useCartItem } from '@/composables/useCartItem';

type PsProduct = GetProductResponse['psdata'];

interface Group {
  group_name: string;
  name: string;
  group_type: string;
  default: number;
  attributes: { name: string }[];
  attributes_quantity: { [key: string]: number };
  options: { id_attribute: string; name: string }[];
}

const route = useRoute();
const router = useRouter();
const { data, refresh, pending } = useProduct();
const { quantityInCart, quantityAvailable, quantitySelectable, isAvailable } = useCartItem(data);

const groups = computed((): Record<string, Group> => data.value?.groups ?? {});

const selectedVariant = (option: string) => route.query[option];

const selectVariant = async (value: string) => {
  await router.replace({
    query: {
      ...route.query,
      variant: value,
    },
  });

  await refresh();
};

const selectedVariants = ref<Record<string, string>>({});

const isVaraintAvailable = (group: any, key: any): boolean => {
  if (!group || !key) return false;

  if (data.value?.allow_out_of_stock == String(1)) return true;

  return group['attributes_quantity'][key] > 0 ? true : false;
};

onMounted(() => {
  for (const groupKey in groups.value) {
    if (!Object.prototype.hasOwnProperty.call(groups.value, groupKey)) continue;

    const group = groups.value[groupKey];

    let param = selectedVariant('variant');

    if (param && typeof param === 'string' && param.includes('-')) {
      let selected = param.split('-')[1];

      if (!group.options.find((option) => option.id_attribute == selected)) {
        selected = group.default.toString();
      }

      selectVariant(`${groupKey}-${selected}`);

      selectedVariants.value[groupKey] = selected;
    } else {
      selectVariant(`${groupKey}-${group.default}`);

      selectedVariants.value[groupKey] = group.default.toString();
    }
  }
});

watch(
  () => route.query.variant,
  async (newVariant) => {
    if (!newVariant) return;
    const [groupKey, value] = newVariant.split('-');
    if (groups.value[groupKey] && selectedVariants.value[groupKey] !== value) {
      selectedVariants.value[groupKey] = value;
      await refresh();
    }
  },
);
</script>

<template>
  <div>
    <div v-if="Object.keys(groups).length > 0">
      <div class="flex flex-col gap-3" v-for="(group, groupKey) in groups" :key="groupKey">
        <Label class="font-semibold text-base">{{ group.name }}</Label>
        <ToggleGroup
          v-model="selectedVariants[groupKey]"
          type="single"
          variant="outline"
          class="justify-start flex-wrap"
          @update:model-value="
            (value) => {
              if (value) {
                selectVariant(`${groupKey}-${value}`);
              }
            }
          "
        >
          <ToggleGroupItem
            v-for="attribute in group.options"
            :key="`${attribute.id_attribute}`"
            :value="`${attribute.id_attribute}`"
            class="relative border min-w-12 font-bold text-base py-2 px-3"
            :disabled="pending || !isVaraintAvailable(group, attribute.id_attribute)"
            :class="{
              'bg-gray-200': !isVaraintAvailable(group, attribute.id_attribute),
            }"
          >
            {{ attribute.name }}
          </ToggleGroupItem>
        </ToggleGroup>
        <!-- <p class="text-sm text-primary font-bold">{{ option.name }} Guide</p> -->
      </div>
    </div>
    <div class="mt-2">
      <div v-if="!pending" class="font-semibold" :class="[isAvailable ? 'text-green-600' : 'text-orange-600']">
        {{ data?.availability_message }}
      </div>
      <div v-else>
        <SfLoaderCircular />
      </div>
    </div>
  </div>
</template>

<style>
.toggle-disabled::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-top: 2px solid rgba(0, 0, 0, 0.6);
  border-bottom: 2px solid rgba(0, 0, 0, 0.6);
  transform: rotate(-45deg);
  pointer-events: none;
}
</style>

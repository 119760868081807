<template>
  <div>
    <VContainer v-if="product" class="px-20">
      <div class="py-5">
        <Breadcrumbs class="breadcrumbs mb-2" :breadcrumbs="product.breadcrumb" />
      </div>

      <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-6 mb-16">
        <section class="grid-in-left-top md:h-full">
          <VGallery :images="product?.images" @showGallery="open" :is-open="isOpen" :product="product" />
        </section>
        <section class="grid-in-right flex flex-col gap-6">
          <div class="flex flex-row divide-x border-gray-500">
            <div class="space-x-1 pr-2">
              <span class="font-normal">Brand:</span>
              <NuxtLink :to="product.manufacturer.url" class="font-bold text-primary hover:underline hover:underline-offset-4">
                {{ product.manufacturer_name }}</NuxtLink
              >
            </div>
            <div class="space-x-1 pl-2">
              <span class="font-bold">SKU:</span>
              <span class="font-medium"> {{ product.mpn }}</span>
            </div>
          </div>

          <h1 class="text-left font-bold text-2xl">{{ product.name }}</h1>

          <div class="prose" v-if="product.description_short" v-html="product.description_short" />

          <section ref="target">
            <VProductPagePrice :prices-data="pricesData" />
          </section>

          <div class="flex flex-col gap-2">
            <div v-if="product.delivery_information">
              {{ product.delivery_information }}
            </div>
            <div v-if="product.out_of_stock == '1' && !!product.delivery_out_stock">
              {{ product.delivery_out_stock }}
            </div>
            <div v-if="product.out_of_stock == '0' && !!product.delivery_in_stock">
              {{ product.delivery_in_stock }}
            </div>
          </div>

          <VProductVariant />

          <div class="flex flex-col gap-2" v-if="product?.cart_rules && product?.cart_rules.length > 0">
            <div v-for="rule in product?.cart_rules">
              <VCartRule :rule="rule" />
            </div>
          </div>

          <hr />

          <div class="flex flex-col gap-3">
            <div class="flex flex-wrap gap-3">
              <QuantitySelector class="h-11" v-model="selectedQuantity" :min="0" :max="quantitySelectable" />

              <Button :disabled="loading || !isAvailable" @click="doAddToCart" class="h-11">
                <SfProgressCircular v-show="loading" class="mr-4" />
                {{ actionText }}
              </Button>

              <!-- <Button variant="outline" class="border-primary h-11 w-11">
              <Heart class="text-primary" :size="32" />
            </Button>

            <Button variant="outline" class="border-primary h-11 w-11">
              <Share2 class="text-primary" :size="32" />
            </Button> -->
            </div>

            <div v-if="quantityInCart > 0 && !isAvailable">
              <div class="bg-red-100 text-red-900 p-1">
                {{ $t(`You have the last item in the cart`) }}
              </div>
              <NuxtLink v-if="quantityInCart > 0" :to="$localePath('/cart')" class="text-blue-600 underline">
                {{ $t('View cart') }}
              </NuxtLink>
            </div>
          </div>

          <div class="flex flex-col gap-2 font-bold text-sm w-80">
            <div class="flex gap-3 whitespace-nowrap">
              <Package class="shrink-0" />
              <span>{{ $t('products.shipping', 'Free shipping starting from 150,00 € to Italy') }}</span>
            </div>
            <div class="flex gap-3 whitespace-nowrap">
              <RefreshCcw /> <span>{{ $t('products.return', 'Easy return and exchange*') }}</span>
            </div>
            <div class="flex gap-3 whitespace-nowrap">
              <CreditCard /> <span>{{ $t('products.payment', 'Secure payments') }}</span>
            </div>
            <div class="flex gap-3 whitespace-nowrap">
              <Headphones /> <span>{{ $t('products.support', 'Dedicated customer support') }}</span>
            </div>
          </div>

          <!-- <UiCard class="bg-gray-100">
          <UiCardContent class="p-6">
            <div class="flex flex-col md:flex-row justify-center items-center md:items-start gap-3 font-bold">
              <div class="grow-0">
                <div class="w-20 h-20 bg-gray-200 rounded"></div>
              </div>
              <div class="flex flex-col gap-3">
                <UiCardTitle class="text-xl font-bold">Promozione del mese!</UiCardTitle>
                <UiCardDescription class="font-normal">
                  Breve descrizione della promo.
                  Breve descrizione della promo.
                  Breve descrizione della promo.
                  <a href="http://" target="_blank" rel="noopener noreferrer" class="text-primary">
                    Link per la promo.
                  </a>
                </UiCardDescription>
              </div>
            </div>
          </UiCardContent>
        </UiCard> -->
        </section>
      </div>
    </VContainer>

    <div class="bg-gray-100 py-8">
      <VContainer>
        <div class="grid grid-cols-1 lg:grid-cols-[2fr,1fr] gap-4">
          <Card class="w-full">
            <CardContent class="p-4 sm:p-6 md:p-10 flex-col justify-start">
              <VSection :title="$t('Description')">
                <div class="prose max-w-4xl" v-html="product?.description" />
              </VSection>
              <VSection :title="$t('Details')" class="mt-8">
                <dl class="divide-y">
                  <div class="py-2 sm:grid sm:grid-cols-3 sm:gap-4">
                    <dt class="text-sm font-medium text-gray-500">
                      {{ $t('Category') }}
                    </dt>
                    <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                      {{ product?.category_name }}
                    </dd>
                  </div>
                  <div
                    class="py-2 sm:grid sm:grid-cols-3 sm:gap-4"
                    v-for="(property, i) in product?.product_info"
                    :key="`property-${i}`"
                  >
                    <dt class="text-sm font-medium text-gray-500">
                      {{ $t(property.name) }}
                    </dt>
                    <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                      {{ property.value }}
                    </dd>
                  </div>
                </dl>
              </VSection>
            </CardContent>
          </Card>
          <div>
            <Card class="hidden md:block w-full sticky top-0">
              <CardContent class="flex flex-col justify-start gap-6 p-4 md:p-6">
                <Card class="aspect-square bg-gray-100">
                  <NuxtImg
                    v-if="image"
                    :src="image"
                    format="webp"
                    :alt="product?.name"
                    loading="lazy"
                    class="aspect-square object-cover w-full"
                  />
                </Card>
                <VProductPagePrice :prices-data="pricesData" />
                <VProductVariant />
                <div class="flex flex-col gap-3">
                  <div class="flex flex-wrap gap-3">
                    <QuantitySelector class="h-11" v-model="selectedQuantity" :min="0" :max="quantitySelectable" />
                    <Button :disabled="loading || !isAvailable" @click="doAddToCart" class="h-11">
                      <SfProgressCircular v-show="loading" class="mr-4" />
                      {{ actionText }}
                    </Button>
                    <!-- <Button variant="outline" class="border-primary h-11 w-11">
                    <Heart class="text-primary" :size="32" />
                  </Button>
                  <Button variant="outline" class="border-primary h-11 w-11">
                    <Share2 class="text-primary" :size="32" />
                  </Button> -->
                  </div>
                </div>
              </CardContent>
            </Card>
          </div>
        </div>
      </VContainer>
    </div>

    <VContainer>
      <!-- <VSection :title="$t('Brand')">
      <UiCard class="flex flex-col lg:flex-row p-6 bg-gray-100 rounded-3xl justify-start items-center gap-[60px]">
        <UiCard class="grow shrink basis-0 self-stretch px-10 py-[30px]">
          <img class="self-stretch grow shrink basis-0" src="" />
        </UiCard>
        <div class="grow shrink basis-0 flex-col justify-start items-start gap-6">
          <div class="text-black text-[32px] font-bold">{{ product?.manufacturer_name }}</div>
          <div class="self-stretch text-black text-base font-normal">SHOEI, fondato nel 1958 in Giappone, è un marchio leggendario nel mondo dei caschi per moto. <br/>Con oltre 60 anni di esperienza e innovazione, SHOEI si è affermato come leader globale nella produzione di caschi di altissima qualità, offrendo ai motociclisti la massima protezione, comfort e stile.</div>
          <div class="text-primary text-base font-bold underline uppercase">Vai al marchio</div>
        </div>
      </UiCard>
    </VSection> -->

      <LazyVisibleVRelatedProducts
        hydrate-on-visible
        class="mt-8"
        :heading="$t('products.related_products', 'Related Products')"
        :id_product="product?.id_product"
      />

      <LazyVisibleRelatedProducts
        hydrate-on-visible
        class="mt-8"
        :heading="$t('products.category_products', 'Category Products')"
        :id_category_default="Number(product?.id_category_default)"
      />
    </VContainer>

    <!-- <UiCard v-show="isVisible" class="md:hidden fixed right-0 bottom-0 w-full rounded-t-2xl z-50">
      <UiCardContent class="flex flex-col justify-start gap-6 p-4">
        <div class="flex justify-between items-center w-full gap-3 text-base">
          <div class="truncate font-semibold text-lg">
            {{ product.name }}
          </div>
          <div class="flex items-center gap-1 font-bold" v-if="isRegular">
            <span class="text-2xl text-red-600 font-bold m-0">{{ pricesData.price }}</span>
          </div>
          <div class="flex items-baseline gap-2 font-bold" v-else>
            <span class="text-2xl text-red-600 font-bold m-0">{{ pricesData.price }}</span>
            <span class="line-through text-sm font-bold">{{ pricesData.regular_price }}</span>
          </div>
        </div>

        <UiSheet>
          <UiSheetTrigger class="w-full" as-child>
            <Button class="rounded-full w-full">
              {{ $t('Add To Cart') }}
            </Button>
          </UiSheetTrigger>
          <UiSheetContent class="rounded-t-xl px-2 pb-0" side="bottom">
            <div class="flex flex-col justify-start gap-6 p-4 md:p-6">
              <h2 class="text-left font-bold text-xl">{{ product.name }}</h2>
              <VProductPagePrice :prices-data="pricesData" />
              <VProductVariant />
              <div class="space-y-2">
                <div class="flex flex-wrap gap-3">
                  <UiQuantitySelector class="h-11" v-model="selectedQuantity" :min="0" :max="quantitySelectable" />
                  <UiSheetClose as-child>
                    <Button :disabled="loading || !isAvailable" @click="doAddToCart" class="h-11">
                      <SfProgressCircular v-if="loading" class="mr-4" />
                      {{ $t('Buy') }}
                    </Button>
                  </UiSheetClose>

                  <Button variant="outline" class="hidden md:block border-primary h-11 w-11">
                  <Heart class="text-primary" :size="32" />
                </Button>
                <Button variant="outline" class="hidden md:block border-primary h-11 w-11">
                  <Share2 class="text-primary" :size="32" />
                </Button>
                </div>
              </div>
            </div>
          </UiSheetContent>
        </UiSheet>
      </UiCardContent>
    </UiCard> -->
  </div>
</template>

<script setup lang="ts">
import { SfProgressCircular, useDisclosure } from '@storefront-ui/vue';
import { defineProduct, useSchemaOrg, defineBreadcrumb } from '@unhead/schema-org/vue';
import { Heart, Share2, Package, RefreshCcw, CreditCard, Headphones } from 'lucide-vue-next';
import { Button } from '~/components/ui/button';

const config = useRuntimeConfig();
const generalStore = useGeneralStore();
const { data: product, refresh: fetchProduct } = useProduct();
const { execute: addToCart, loading } = useAddToCart();
const { isOpen, open } = useDisclosure({ initialValue: false });
const gtm = useGtm();
const { t } = useI18n();

onMounted(() => {
  gtm?.trackEvent({
    event: 'view_item',
    ecommerce: {
      value: product.value?.price_amount,
      items: [
        {
          google_business_vertical: 'retail',
          item_id:
            product.value?.id_product + (product.value?.id_product_attribute ? '-' + product.value?.id_product_attribute : ''),
          item_brand: product.value?.manufacturer_name,
          price: product.value?.price_amount,
        },
      ],
    },
  });
});

await fetchProduct();

const { quantityInCart, quantityAvailable, quantitySelectable, isAvailable } = useCartItem(product);

const title = computed(() => product.value?.name ?? '');

const selectedQuantity = ref(1);

async function doAddToCart() {
  if (!product.value) return;
  await addToCart({
    ...product.value,
    id_product: product.value.id_product,
    id_product_attribute: product.value.id_product_attribute,
    qty: selectedQuantity.value,
  });
  await fetchProduct();
  if (!generalStore.isCartSidebarOpen) {
    generalStore.toggleCartSidebar();
  }
}

useHead({
  title: title.value,
  meta: [],
  link: [
    {
      rel: 'canonical',
      href: product.value?.product_url,
    },
    ...Object.keys(product.value?.alternative_urls).map((lang) => {
      return {
        rel: 'alternate',
        hreflang: lang,
        href: product.value?.alternative_urls[lang],
      };
    }),
  ],
});

useSeoMeta({
  title: product.value?.name,
  ogTitle: product.value?.name,
  description: product.value?.meta_description,
  ogDescription: product.value?.meta_description,
  ogImage: product.value?.default_image?.url,
});

let schema = [];

schema.push(
  defineBreadcrumb({
    itemListElement:
      product.value?.breadcrumb.map((b) => ({
        name: b?.title,
        item: b.url.replace(useRuntimeConfig().public.prestashop_url, config.public.app_url),
      })) ?? [],
    numberOfItems: product.value?.breadcrumb?.length ?? 0,
  }),
);

if (product.value?.variants && product.value?.variants.length > 0) {
  schema.push({
    '@type': 'ProductGroup',
    '@id': '#product_group',
    name: product.value?.name,
    image: product.value?.default_image?.url ?? '',
    url: product.value?.product_url,
    brand: {
      name: product.value?.manufacturer_name ?? '',
    },
    productGroupID: product.value?.id_product,
    variesBy: ['https://schema.org/size'],
  });

  for (let index = 0; index < product.value.variants.length; index++) {
    const element = product.value.variants[index];
    schema.push({
      '@context': 'https://schema.org',
      '@type': 'Product',
      isVariantOf: { '@id': '#product_group' },
      name: product.value?.name,
      description: product.value?.meta_description,
      sku: element.reference,
      mpn: element.reference,
      size: element.attribute_name,
      offers: {
        '@type': 'Offer',
        price: product.value?.price_amount,
        priceCurrency: product.value?.currency_iso_code,
        url: element.url,
        priceValidUntil: new Date().toISOString(),
        availability: element.availability,
      },
    });
  }
} else {
  schema.push(
    defineProduct({
      name: product.value?.name ?? '',
      description: product.value?.meta_description,
      image: product.value?.default_image?.url ?? '',
      brand: {
        name: product.value?.manufacturer_name ?? '',
      },
      sku: product.value?.reference,
      mpn: product.value?.mpn,
      gtin13: product.value?.ean13,
      category: product.value?.category_name,
      offers: [
        {
          price: product.value?.price_amount,
          priceCurrency: product.value?.currency_iso_code,
          url: product.value?.product_url,
          priceValidUntil: new Date().toISOString(),
          availability:
            (product.value?.quantity ?? 0 > 0) ? 'InStock' : product.value?.available_for_order ? 'BackOrder' : 'OutOfStock',
        },
      ],
    }),
  );
}

useSchemaOrg(schema);

const image = computed(() => {
  return (
    product.value?.default_image?.large?.url?.replace('home_default', 'large_default') ??
    product.value?.default_image?.url.replace('home_default', 'large_default')
  );
});

const { data: pricesData, isRegular } = usePricesData(product);

const target = ref(null);
const isVisible = ref(false);
let observer = ref(null);

onMounted(() => {
  observer.value = new IntersectionObserver(([entry]) => {
    if (!entry.isIntersecting) {
      const targetPosition = entry.boundingClientRect.y;
      console.log(targetPosition, targetPosition < 0);
      isVisible.value = targetPosition < 0;
    } else {
      isVisible.value = false;
    }
  });

  if (target.value) {
    observer.value.observe(target.value);
  }
});

onUnmounted(() => {
  if (observer.value) {
    observer.value.disconnect();
  }
});

const actionText = computed(() => {
  if (loading.value) {
    return t('Loading') + '...';
  }

  if(!isAvailable && product.value?.allow_oosp > 0) {
    return t('Preorder', 'Preorder'); 
  }

  if (product.value?.id_product_attribute == '0') {
    return t('Add to cart');
  }

  return t('products.buy','Buy');
});
</script>

<style scoped>
.prose h1,
.prose h2,
.prose h3 {
  font-family: var(--font-family-primary);
  margin-bottom: 0.5rem;
  line-height: var(--line-height-default);
}

.prose h1 {
  font-size: var(--h1-size);
  font-weight: var(--font-bold);
}

.prose h2 {
  font-size: var(--h2-size);
  font-weight: var(--font-semibold);
}

.prose h3 {
  font-size: var(--h3-size);
  font-weight: var(--font-semibold);
}

.prose p,
.prose span,
.prose table,
.prose ul,
.prose ol {
  font-family: var(--font-family-secondary);
  font-size: var(--paragraph-size);
  font-weight: var(--font-regular);
  line-height: var(--line-height-default);
  color: var(--paragraph-color);
}

.prose table {
  border-collapse: collapse;
  width: 100%;
  margin-top: 1rem;
}

.prose table th,
.prose table td {
  padding: 0.5rem;
  text-align: left;
  border: 1px solid var(--paragraph-color);
}
</style>

<template>
  <div class="inline-flex flex-col items-center h-10" data-testid="quantity-selector">
    <div class="flex border border-neutral-300 rounded h-full w-full">
      <SfButton
        variant="tertiary"
        :disabled="modelValue <= min"
        square
        class="rounded-r-none"
        :aria-label="$t('quantitySelectorDecrease')"
        data-testid="quantity-selector-decrease-button"
        @click="$emit('update:modelValue', clamp(modelValue - 1, min, max))"
      >
        <SfIconRemove />
      </SfButton>
      <input
        :value="modelValue"
        type="number"
        role="spinbutton"
        :class="inputClasses"
        :min="min"
        :max="max"
        @input="$emit('update:modelValue', clamp($event.target?.value ?? 0, min, max))"
      />
      <SfButton
        variant="tertiary"
        :disabled="modelValue >= max"
        square
        class="rounded-l-none"
        :aria-label="$t('quantitySelectorIncrease')"
        data-testid="quantity-selector-increase-button"
        @click="$emit('update:modelValue', clamp(modelValue + 1, min, max))"
      >
        <SfIconAdd />
      </SfButton>
    </div>
  </div>
</template>

<script setup lang="ts">
import { clamp } from '@storefront-ui/shared';
import { SfButton, SfIconAdd, SfIconRemove, useId } from '@storefront-ui/vue';

const props = defineProps<{
  modelValue: number,
  min: number,
  max?: number,
}>();

const inputClasses = computed(() => [
  'appearance-none flex-1 mx-2 w-10 text-center bg-transparent font-medium [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-inner-spin-button]:display-none [&::-webkit-inner-spin-button]:m-0 [&::-webkit-outer-spin-button]:display-none [&::-webkit-outer-spin-button]:m-0 [-moz-appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none disabled:placeholder-disabled-900 focus-visible:outline focus-visible:outline-offset focus-visible:rounded-sm',
  'border-none',
]);
</script>
